<template>
  <div class='flex flex-row'>
    <table class='text-base table-fixed' style='width:400px;'>
      <tbody>
        <tr><td>.</td></tr>
        <tr><td>투자건수</td></tr>
        <tr><td>투자금액</td></tr>
        <tr><td>회수금액</td></tr>
      </tbody>
      <tfoot>
          <td>
            <button class='font-bold text-left border rounded-md border-transparent hover:border-gray-500'
              @click='copyToClipboard'>Copy To Clipboard</button>
          </td>
      </tfoot>
    </table>
    <div class='overflow-x-scroll' style='width:1300px;'>
      <table class='text-base table-fixed'>
        <thead>
          <tr>
            <td v-for='(portfolio,  index) in portfolios' :key='`portfolio-${index}`'
              class='px-2 py-2 text-left'>
              {{ portfolio.investment_date }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for='(portfolio,  index) in portfolios' :key='`portfolio-${index}`'
              class='px-2 py-2 text-right'>
              {{ portfolio.investment_count }}
            </td>
          </tr>
          <tr>
            <td v-for='(portfolio,  index) in portfolios' :key='`portfolio-${index}`'
              class='px-2 py-2 text-right'>
              {{ toNumber(portfolio.investment_value) }} 억원
            </td>
          </tr>
          <tr>
            <td v-for='(portfolio,  index) in portfolios' :key='`portfolio-${index}`'
              class='px-2 py-2 text-right'>
              {{ toNumber(portfolio.collection_value) }} 억원
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import numbro                      from 'numbro'


export default {
  name: 'PeriodReport',
  components: {
  },
  props: [
    'portfolios',
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    toNumber (number) {
      if (number) {
        return numbro(parseInt(Math.round(number / 100000000))).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    copyToClipboard () {
      let string = ''

      string = '\t'
      this.portfolios.forEach( portfolio => {
        string += portfolio.investment_date + '\t'
      })
      string += '\n'

      string += '투자건수' + '\t'
      this.portfolios.forEach( portfolio => {
        string += portfolio.investment_count + '\t'
      })
      string += '\n'

      string += '투자금액' + '\t'
      this.portfolios.forEach( portfolio => {
        string += this.toNumber(portfolio.investment_value) + '\t'
      })
      string += '\n'

      string += '회수금액' + '\t'
      this.portfolios.forEach( portfolio => {
        string += this.toNumber(portfolio.collection_value) + '\t'
      })

      this.$copyText(string)
    },
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-center py-3 px-2 uppercase tracking-wide;
    background-color: #FFF;
    white-space: nowrap;
    z-index: 2;
  }
  td {
    @apply py-3 pl-2 pr-6 border-b;
    white-space: nowrap;
  }
</style>
